import { BigNumber } from 'ethers';
import DrLutzTheGoat from '../assets/images/webstore/drlutzgoat.png';
import DrLutzPengu from '../assets/images/webstore/drlutzpengu.png';
import RoninGAM3R from '../assets/images/webstore/Ronin.png';
import BjornShiba from '../assets/images/webstore/shiba.png';
import BjornSanta from '../assets/images/webstore/Santa.png';
import TrueshotElffya from '../assets/images/webstore/Elffya.png';
import ZapZeus from '../assets/images/webstore/Zeus.png';
import VegaDoge from '../assets/images/webstore/Doge.png';
import BlazyEnlightenedBlazy from '../assets/images/webstore/enlightenedblazy.png';
import BlazyGoldenEmpressBlazy from '../assets/images/webstore/goldenempressblazy.png';
import DrLutzManatech from '../assets/images/webstore/manatechdrlutz.png';
import AmunCursedEssence from '../assets/images/webstore/cursedessenceamun.png';
import ZapStormChampion from '../assets/images/webstore/stormchampionzap.png';
import ZapRedEmperor from '../assets/images/webstore/redemporerzap.png';
import GailZephyrMistress from '../assets/images/webstore/Zephyrmistressgail.png';
import GailDragonSlayer from '../assets/images/webstore/DragonSlayerGail.png';
import FrigardToxicFrigard from '../assets/images/webstore/ToxxicFrigard.png';
import BrienneCrimsonArmor from '../assets/images/webstore/CrimsonArmorBrienne.png';
import VegaBloodReaper from '../assets/images/webstore/BloodreaperVega.png';
import VegaUmbralHarvester from '../assets/images/webstore/UmbralHarvester.png';
import VegaManaReaver from '../assets/images/webstore/ManaReaver.png';
import BjornTundraLord from '../assets/images/webstore/TundraLordBjorn.png';
import BjornManaSplitter from '../assets/images/webstore/ManaSplitter.png';
import KarasAcolyteKaras from '../assets/images/webstore/AcolyteKaras.png';
import RoninRisingShogunRonin from '../assets/images/webstore/RisingSHOgunRonin.png';
import RoninVoidstalker from '../assets/images/webstore/VoidStalker.png';










export const chainId = Number(process.env.REACT_APP_CHAIN_ID) || 97;
export const E18 = BigNumber.from(10).pow(18);
export const E9 = BigNumber.from(10).pow(9);
export const COINGECKO_API_ENDPOINT = 'https://api.coingecko.com/api';
export const numberRegex = /^[0-9]*\.?[0-9]*$/;
export const INVENTORY_SUBGRAPH_URL =
  'https://api.thegraph.com/subgraphs/name/daisai3/magiccraft_nft';




export const GEM_PACK_DATA = [
  {
    id: 1,
    pack_name: 'Starter Shard',
    price_in_usd: 3.5,
    gems: 50,
  },
  {
    id: 2,
    pack_name: 'Explorer Bundle',
    price_in_usd: 7.0,
    gems: 110,
  },
  {
    id: 3,
    pack_name: "Adventurer's Cache",
    price_in_usd: 14.0,
    gems: 230,
  },
  {
    id: 4,
    pack_name: "Champion's Treasury",
    price_in_usd: 35.0,
    gems: 600,
  },
  {
    id: 5,
    pack_name: 'Heroic Vault',
    price_in_usd: 70.0,
    gems: 1250,
  },
];



export const WEBSTORE_DATA = [
  {
    "id": 1,
    "name": "Dr Lutz The Goat",
    "mcrtPrice": 25000,
    "image": DrLutzTheGoat
  },
  {
    "id": 2,
    "name": "Dr Lutz Pengu",
    "mcrtPrice": 27000,
    "image": DrLutzPengu
  },
  {
    "id": 3,
    "name": "Ronin GAM3R",
    "mcrtPrice": 25000,
    "image": RoninGAM3R
  },
  {
    "id": 4,
    "name": "Bjorn Shiba",
    "mcrtPrice": 26500,
    "image": BjornShiba
  },
  {
    "id": 5,
    "name": "Bjorn Santa",
    "mcrtPrice": 26500,
    "image": BjornSanta
  },
  {
    "id": 6,
    "name": "Trueshot Elffya",
    "mcrtPrice": 26500,
    "image": TrueshotElffya
  },
  {
    "id": 7,
    "name": "Zap Zeus",
    "mcrtPrice": 26500,
    "image": ZapZeus
  },
  {
    "id": 8,
    "name": "Vega Doge",
    "mcrtPrice": 17000,
    "image": VegaDoge
  },
  {
    "id": 9,
    "name": "Blazy Enlightened Blazy",
    "mcrtPrice": 20000,
    "image": BlazyEnlightenedBlazy
  },
  {
    "id": 10,
    "name": "Blazy Golden Empress Blazy",
    "mcrtPrice": 26500,
    "image": BlazyGoldenEmpressBlazy
  },
  {
    "id": 11,
    "name": "Dr Lutz Manatech",
    "mcrtPrice": 26500,
    "image": DrLutzManatech
  },
  {
    "id": 12,
    "name": "Amun Cursed Essence",
    "mcrtPrice": 12000,
    "image": AmunCursedEssence
  },
  {
    "id": 13,
    "name": "Zap Storm Champion",
    "mcrtPrice": 20000,
    "image": ZapStormChampion
  },
  {
    "id": 14,
    "name": "Zap Red Emperor",
    "mcrtPrice": 20000,
    "image": ZapRedEmperor
  },
  {
    "id": 15,
    "name": "Gail Zephyr Mistress",
    "mcrtPrice": 20000,
    "image": GailZephyrMistress
  },
  {
    "id": 16,
    "name": "Gail Dragon Slayer",
    "mcrtPrice": 26500,
    "image": GailDragonSlayer
  },
  {
    "id": 17,
    "name": "Frigard Toxic Frigard",
    "mcrtPrice": 12000,
    "image": FrigardToxicFrigard
  },
  {
    "id": 18,
    "name": "Brienne Crimson Armor",
    "mcrtPrice": 20000,
    "image": BrienneCrimsonArmor
  },
  {
    "id": 19,
    "name": "Vega Blood Reaper",
    "mcrtPrice": 26500,
    "image": VegaBloodReaper
  },
  {
    "id": 20,
    "name": "Vega Umbral Harvester",
    "mcrtPrice": 17000,
    "image": VegaUmbralHarvester
  },
  {
    "id": 21,
    "name": "Vega Mana Reaver",
    "mcrtPrice": 17000,
    "image": VegaManaReaver
  },
  {
    "id": 22,
    "name": "Bjorn Tundra Lord",
    "mcrtPrice": 26500,
    "image": BjornTundraLord
  },
  {
    "id": 23,
    "name": "Bjorn Mana Splitter",
    "mcrtPrice": 18000,
    "image": BjornManaSplitter
  },
  {
    "id": 24,
    "name": "Karas Acolyte Karas",
    "mcrtPrice": 20000,
    "image": KarasAcolyteKaras
  },
  {
    "id": 25,
    "name": "Ronin Rising Shogun Ronin",
    "mcrtPrice": 26500,
    "image": RoninRisingShogunRonin
  },
  {
    "id": 26,
    "name": "Ronin Voidstalker",
    "mcrtPrice": 18000,
    "image": RoninVoidstalker
  }
]
