import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Card,
  Button,
  Spinner,
  Row,
  Col,
  Modal,
  Carousel,
} from 'react-bootstrap';
import mcrtLogo from 'assets/icon-mcrt.png';
import './MerchandiseItems.css';
import { ethers, BigNumber } from 'ethers';
import { toast } from 'react-toastify';

const MerchandiseItems = ({ account, magicMCRT, isError }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [mcrtPrice, setMCRTPrice] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          'https://lobby-api-prod.magiccraft.io/list-merchdice',
        );
        if (response.data.status === 'success') {
          setProducts(response.data.products);
        } else {
          setError('Failed to fetch products');
        }
      } catch (err) {
        setError('Error connecting to the server');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
    getMCRTPrice();
  }, []);

  const getMCRTPrice = async () => {
    try {
      const lobbyBackURL =
        process.env.REACT_APP_LOBBY_BACK_URL ||
        'https://lobby-api-prod.magiccraft.io';
      const response = await axios.get(`${lobbyBackURL}/mcrt-price`);
      const price = response?.data?.price;
      if (price) {
        setMCRTPrice(price);
      }
    } catch (error) {
      console.error('Failed to fetch MCRT price:', error);
      setMCRTPrice(0);
    }
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    // Set the default variant
    const defaultVariant =
      product.variants.find((v) => v.is_default) || product.variants[0];
    setSelectedVariant(defaultVariant);
    setShowModal(true);
  };

  const handleVariantChange = (variantId) => {
    const variant = selectedProduct.variants.find(
      (v) => v.options[0] === variantId,
    );
    setSelectedVariant(variant);
  };

  const formatPrice = (price) => {
    return `$${(price / 100).toFixed(2)}`;
  };

  const calculateMCRTPrice = (usdPrice) => {
    if (!mcrtPrice || mcrtPrice === 0) return 'N/A';
    const mcrtAmount = (usdPrice / mcrtPrice).toFixed(2);
    return `${mcrtAmount} MCRT`;
  };

  // Assign a rarity to each product for visual consistency with NFT cards
  const getRarity = (product) => {
    const price =
      product.variants.find((v) => v.is_default)?.price ||
      product.variants[0]?.price;
    if (price >= 5000) return { name: 'Legendary', color: '#f5a623' };
    if (price >= 2500) return { name: 'Epic', color: '#9013fe' };
    return { name: 'Rare', color: '#4a90e2' };
  };

  // Get product type for display
  const getProductType = (product) => {
    const categories = product.product_type || 'Merchandise';
    return categories.charAt(0).toUpperCase() + categories.slice(1);
  };

  // Format price with commas
  const numberWithCommas = (x) => {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  };

  // Format large numbers with K, M, etc.
  const nFormatter = (num, digits) => {
    const lookup = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'K' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'G' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
      : '0';
  };

  const convertToBigNumber = (amount) => {
    // Convert MCRT amount to the contract's expected format (with 18 decimals)
    return ethers.utils.parseUnits(amount.toString(), 9).toString();
  };

  const onBuyWithMCRT = async () => {
    if (!selectedVariant) return;

    try {
      if (isError) {
        throw new Error('Error while processing transaction');
      }

      setIsProcessing(true);

      // Calculate MCRT amount from USD price
      const usdPrice = selectedVariant.price / 100;
      const mcrtAmount = (usdPrice / mcrtPrice).toFixed(2);
      const mcrtAmt = convertToBigNumber(mcrtAmount);

      const treasury_wallet_address =
        process.env.REACT_APP_TREASURY_WALLET_ADDRESS;

      // Check if there's enough allowance
      const allowance = await magicMCRT.methods
        .allowance(account, treasury_wallet_address)
        .call();

      if (
        BigNumber.from(String(allowance)).lt(BigNumber.from(String(mcrtAmt)))
      ) {
        await magicMCRT.methods.approve(account, mcrtAmt).send({
          from: account,
          gasPrice: ethers.utils.parseUnits('10', 'gwei'),
          gas: 100000,
        });
      }

      // Execute the transfer
      const contract = await magicMCRT.methods
        .transferFrom(account, treasury_wallet_address, mcrtAmt)
        .send({
          from: account,
          gasPrice: ethers.utils.parseUnits('10', 'gwei'),
          gas: 100000,
        });

      if (!contract.transactionHash) {
        throw new Error('There was an error in completing the transaction.');
      }

      // Record the purchase
      // const { data } = await axios.post(
      //   `${process.env.REACT_APP_LOBBY_BACK_URL}/record-merchandise-purchase`,
      //   {
      //     tx_hash: contract.transactionHash,
      //     product_id: selectedProduct.id,
      //     variant_id: selectedVariant.id,
      //     mcrt_amount: mcrtAmount,
      //     usd_amount: usdPrice,
      //   },
      // );

      setIsProcessing(false);

      // if (data.error) {
      //   toast.error(data.error);
      // } else {
      toast.success(
        `Congratulations, you have successfully completed your purchase.`,
      );
      setShowModal(false);
      // }
    } catch (error) {
      console.error('Purchase error:', error);
      setIsProcessing(false);
      toast.error(`There was an error while processing your transaction.`);
    }
  };

  if (loading) {
    return (
      <div className="nft-inventory__empty">
        <div className="nft-inventory__spinner"></div>
        <h3 style={{ marginTop: '20px' }}>Loading merchandise...</h3>
      </div>
    );
  }

  if (error) {
    return (
      <div className="nft-inventory__empty">
        <h3>Error</h3>
        <p>{error}</p>
      </div>
    );
  }

  if (products.length === 0) {
    return (
      <div className="nft-inventory__empty">
        <h3>No merchandise available at this time</h3>
      </div>
    );
  }

  return (
    <div className="nft-inventory" style={{ padding: '0 16px' }}>
      <div style={{ textAlign: 'center', marginBottom: '40px' }}>
        <h2
          style={{
            fontSize: '32px',
            fontWeight: 'bold',
            color: 'white',
            marginBottom: '16px',
          }}
        >
          Official Merchandise
        </h2>
        <p
          style={{
            fontSize: '18px',
            color: '#76779a',
            maxWidth: '600px',
            margin: '0 auto',
          }}
        >
          Exclusive MagicCraft collectibles and apparel
        </p>
      </div>

      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '24px',
          justifyContent: 'center',
        }}
      >
        {products.map((product) => {
          const defaultPrice =
            product.variants.find((v) => v.is_default)?.price ||
            product.variants[0]?.price;
          const usdPrice = defaultPrice / 100;
          const productType = getProductType(product);
          const mcrtAmount = calculateMCRTPrice(usdPrice).split(' ')[0];

          return (
            <div key={product.id} onClick={() => handleProductClick(product)}>
              <div className="nft-inventory__item merchandise-item">
                <div className="merchandise-image-container">
                  <img
                    className="merchandise-image"
                    src={
                      product.images.find((img) => img.is_default)?.src ||
                      product.images[0]?.src
                    }
                    alt={product.title}
                    loading="lazy"
                  />
                </div>
                <div className="nft-inventory__item-content">
                  <div
                    style={{ marginBottom: '10px' }}
                    className="d-flex align-items-center"
                  >
                    <h3 style={{ color: 'white', fontSize: 18 }}>
                      {product.title}
                    </h3>
                  </div>
                  <div className="d-flex align-items-center">
                    <div
                      className="item-hero-knight"
                      style={{ backgroundColor: '#232449' }}
                    >
                      {productType}
                    </div>
                  </div>

                  <div
                    className="d-flex align-items-center"
                    style={{ marginTop: 4 }}
                  >
                    <div className="item-price-gems">
                      ${numberWithCommas(usdPrice.toFixed(2))}
                    </div>
                  </div>

                  {mcrtPrice > 0 && (
                    <div
                      className="d-flex align-items-center"
                      style={{ marginTop: 4 }}
                    >
                      <img src={mcrtLogo} width={16} height={16} alt="MCRT" />
                      <div className="item-price-token">
                        {numberWithCommas(
                          nFormatter(parseFloat(mcrtAmount), 2),
                        )}
                      </div>
                      <span className="item-price-dollar">MCRT</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {/* Product Detail Modal */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        centered
        className="merchandise-modal"
        dialogClassName="modal-90w"
      >
        {selectedProduct && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>{selectedProduct.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col md={6}>
                  <Carousel
                    className="product-carousel"
                    indicators={selectedProduct.images.length > 1}
                  >
                    {selectedProduct.images.map((image, index) => (
                      <Carousel.Item key={index}>
                        <img
                          className="d-block w-100"
                          src={image.src}
                          alt={`${selectedProduct.title} - View ${index + 1}`}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </Col>
                <Col md={6}>
                  <div className="product-details">
                    <div className="modal-price-container">
                      <h4 className="product-modal-price">
                        {formatPrice(selectedVariant?.price || 0)}
                      </h4>
                      <div className="modal-mcrt-price">
                        {calculateMCRTPrice(
                          (selectedVariant?.price || 0) / 100,
                        )}
                      </div>
                    </div>
                    <div
                      className="product-description"
                      dangerouslySetInnerHTML={{
                        __html: selectedProduct.description,
                      }}
                    />

                    {selectedProduct.options.map((option) => (
                      <div key={option.name} className="product-options">
                        <h5>{option.name}</h5>
                        <div className="option-buttons">
                          {option.values.map((value) => (
                            <Button
                              key={value.id}
                              variant={
                                selectedVariant &&
                                selectedVariant.options.includes(value.id)
                                  ? 'primary'
                                  : 'outline-primary'
                              }
                              onClick={() => handleVariantChange(value.id)}
                              className="option-button"
                            >
                              {value.title}
                            </Button>
                          ))}
                        </div>
                      </div>
                    ))}

                    <div className="d-flex mt-4 justify-content-between">
                      <Button
                        variant="success"
                        className="buy-button"
                        onClick={() =>
                          window.open('https://magiccraft.io/shop', '_blank')
                        }
                      >
                        <i className="fas fa-shopping-cart me-2"></i>
                        Buy with USD
                      </Button>

                      <Button
                        variant="primary"
                        className="buy-button"
                        onClick={onBuyWithMCRT}
                        disabled={isProcessing || !account || mcrtPrice <= 0}
                      >
                        {isProcessing ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className="me-2"
                            />
                            Processing...
                          </>
                        ) : (
                          <>
                            <img
                              src={mcrtLogo}
                              width={16}
                              height={16}
                              alt="MCRT"
                              className="me-2"
                            />
                            Pay with MCRT
                          </>
                        )}
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Modal.Body>
          </>
        )}
      </Modal>
    </div>
  );
};

export default MerchandiseItems;
